import { Color, Fog, OrthographicCamera, Vector2 } from "three";
import { PerspectiveCamera, Scene, WebGLRenderer } from "three";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { ZoomBlur } from "./shaders/ZoomBlur";
import UA from "../../utils/ua";
import { FinalShader } from "./shaders/FinalShader";
import debounce from "lodash/debounce";

export default class Base {
    renderList: any;
    resizeList: any;
    containerEl: HTMLElement;
    stage: any;
    config: any;
    mouse: any;
    isRender: boolean = false;
    renderId: number;

    subScene: Scene = new Scene();
    introScene: Scene = new Scene();
    scene: Scene;

    currentDrawScene: string = "main";

    camera: PerspectiveCamera;
    subCamera: OrthographicCamera;
    editCamera: PerspectiveCamera;
    renderer: WebGLRenderer;

    composer: EffectComposer;

    screenZ: number;

    isEditMode: boolean = false;

    dummyEl: HTMLElement;

    effectParams = {
        bloomStrength: 0.2,
        bloomThreshold: 0.9,
        bloomRadius: 0,

        zoomBlurStrength: 0,
        zoomBlurOpacity: 1,

        uScale: 2.7,
        uPower: 16,
        uSize: 0.13,
        uOpacity: 1.0,
    };

    bloomPass: bloomPass;
    zoomBlurPass: ShaderPass;
    filnalShaderPass: ShaderPass;

    constructor(containerEl, option = {}) {
        this.renderList = {};
        this.resizeList = {};

        this.containerEl = containerEl;

        this.stage = {
            width: 1920,
            height: 1080,
            aspect: 4 / 3,
            marginHeight: 1080,
        };

        this.mouse = { x: 0, y: 0, cx: 0, cy: 0, _cx: 0, _cy: 0, rx: 0, ry: 0 };

        this.defineSize();

        this.config = {
            camera_fov: 45,
            camera_near: 1,
            camera_far: 30000,

            render_antialias: true,
            render_alpha: false,
            render_pixelRatio: window.devicePixelRatio,

            render_clearColor: 0x000000,
            render_clearColorAlpha: 1,
            render_autoClearColor: true,
        };

        this.config = Object.assign(this.config, option);

        this.isRender = false;

        /*
			Scene, Camera, Renderer
		*/
        this.scene = new Scene();
        // this.scene.fog = new Fog(new Color(0xff0000), 500, 1000);

        this.camera = new PerspectiveCamera(
            this.config.camera_fov,
            this.stage.aspect,
            this.config.camera_near,
            this.config.camera_far
        );

        this.renderer = new WebGLRenderer({
            antialias: this.config.render_antialias,
            // alpha: this.config.render_alpha,
            stencil: false,
            depth: false,
        });

        this.screenZ = this.computeZ(0);
        this.camera.position.z = this.screenZ;

        this.renderer.setClearColor(this.config.render_clearColor, 1);
        this.renderer.setPixelRatio(this.config.render_pixelRatio);
        this.renderer.setSize(this.stage.width, this.stage.height);
        this.render = this.render.bind(this);

        if (this.containerEl) this.containerEl.prepend(this.renderer.domElement);

        /*
            postprocessing
        */

        this.filnalShaderPass = new ShaderPass(FinalShader);
        this.filnalShaderPass.uniforms.uSize.value = this.effectParams.uSize;
        this.filnalShaderPass.uniforms.uPower.value = this.effectParams.uPower;
        this.filnalShaderPass.uniforms.uScale.value = this.effectParams.uScale;
        this.filnalShaderPass.uniforms.uResolution.value = new Vector2(
            this.stage.width,
            this.stage.height
        );

        const renderScene = new RenderPass(this.scene, this.camera);
        this.bloomPass = new UnrealBloomPass(
            new Vector2(window.innerWidth, window.innerHeight),
            this.effectParams.bloomStrength,
            this.effectParams.bloomRadius,
            this.effectParams.bloomThreshold
        );

        this.bloomPass.threshold = this.effectParams.bloomThreshold;
        this.bloomPass.strength = this.effectParams.bloomStrength;
        this.bloomPass.radius = this.effectParams.bloomRadius;

        this.zoomBlurPass = new ShaderPass(ZoomBlur);

        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(renderScene);
        this.composer.addPass(this.bloomPass);
        this.composer.addPass(this.zoomBlurPass);
        this.composer.addPass(this.filnalShaderPass);
        // this.zoomBlurPass.renderToScreen = true;

        this.zoomBlurPass.uniforms["resolution"].value = new Vector2(
            this.stage.width,
            this.stage.height
        );
        this.zoomBlurPass.uniforms["center"].value = new Vector2(
            this.stage.width * 0.5,
            this.stage.height * 0.5
        );

        /*
			Event , Stats, Helpers
		*/
        this.resize = this.resize.bind(this);

        //@ts-ignore
        window.addEventListener("resize", debounce(this.resize, 10));

        if (UA.isPC) {
            window.addEventListener("mousemove", (e) => {
                let _x = this.mouse.x;
                let _y = this.mouse.y;

                this.mouse.x = e.clientX;
                this.mouse.y = e.clientY;
                let widthHalf = window.innerWidth * 0.5;
                let heightHalf = window.innerHeight * 0.5;
                this.mouse.cx = this.mouse.x - widthHalf;
                this.mouse.cy = -(this.mouse.y - heightHalf);
                this.mouse.rx = this.mouse.cx / widthHalf;
                this.mouse.ry = this.mouse.cy / heightHalf;

                let dx = _x - this.mouse.x;
                let dy = _y - this.mouse.y;
                this.mouse.acc = Math.min(100, Math.sqrt(dx * dx + dy * dy)) / 100;
            });
        }
    }

    defineSize() {
        this.stage.marginHeight =
            this.containerEl.clientHeight - document.documentElement.clientHeight;
        this.stage.width = this.containerEl.clientWidth;
        this.stage.height = this.containerEl.clientHeight - this.stage.marginHeight;
        this.stage.aspect = this.stage.width / this.stage.height;
    }

    resize(force: boolean = false) {
        if (this.zoomBlurPass) {
            this.zoomBlurPass.uniforms["resolution"].value = new Vector2(
                this.stage.width,
                this.stage.height
            );
            this.zoomBlurPass.uniforms["center"].value = new Vector2(
                this.stage.width * 0.5,
                this.stage.height * 0.5
            );
        }

        if (this.bloomPass) {
            this.bloomPass.resolution.x = this.stage.width;
            this.bloomPass.resolution.y = this.stage.height;
        }

        setTimeout(() => {
            this.filnalShaderPass.uniforms.uResolution.value = new Vector2(
                this.stage.width,
                this.stage.height
            );
        }, 10);
        this.defineSize();
        this.screenZ = this.computeZ(0);
        // if (this.scene) this.scene.position.y = this.stage.marginHeight;

        this.camera.aspect = this.stage.aspect;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.stage.width, this.stage.height);
        this.composer.setSize(this.stage.width, this.stage.height);
        this.updateResizeList(this.stage);
    }

    computeZ(targetZ, targetHeight1 = this.stage.height, targetHeight2 = this.stage.height) {
        let vFOV = (this.camera.fov * Math.PI) / 180;
        let vHeightPartial = 2 * Math.tan(vFOV / 2);
        var p1 = targetHeight1 * this.stage.height;
        var p2 = targetZ * vHeightPartial;
        var p3 = targetHeight2 * vHeightPartial;
        var p4 = targetHeight2 * p2;
        var p5 = p1 + p4;
        var z = p5 / p3;
        return z;
    }

    /*
        render list, resize list
    */

    renderStart() {
        if (!this.isRender) {
            requestAnimationFrame(this.render);
        }
        this.isRender = true;
    }

    renderStop() {
        this.isRender = false;
        cancelAnimationFrame(this.renderId);
    }

    addResizeList(key, target) {
        this.resizeList[key] = target;
    }

    removeRenderList(key) {
        delete this.renderList[key];
    }

    removeResizeList(key) {
        delete this.resizeList[key];
    }

    updateResizeList(...arg: any) {
        for (let key in this.resizeList) {
            this.resizeList[key](...arg);
        }
    }

    render() {
        this.renderId = requestAnimationFrame(this.render);

        this.update();

        // this.renderer.render(this.scene, this.camera);
        this.composer.render();

        if (this.bloomPass) {
            this.bloomPass.strength = this.effectParams.bloomStrength;
        }

        this.mouse._cx += (this.mouse.cx - this.mouse._cx) * 0.1;
        this.mouse._cy += (this.mouse.cy - this.mouse._cy) * 0.1;
    }

    update() {}
}
