import { gsap } from "gsap";
import {
    BoxGeometry,
    Color,
    Group,
    Material,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Points,
    ShapeGeometry,
    Vector3,
} from "three";
import config from "./config";
import { gui, gui_vec3 } from "../../utils/GUI";
import PathLineBase from "./util/PathLine";
import { SVGLoader } from "./util/SVGLoader";
import { makeSimpleBox, pointToPosition } from "./utils";
import Base from "./Base";
import { MeshLineGeometry } from "meshline";

interface TypeConfig {}

interface TypeTime {
    count: number;
}

export default class Logo {
    static flowLineCount: number = 0;
    static logoLineCount: number = 0;
    bit: number = 0;
    bitStrokePower: number = 0.2;
    bitPower: number = 0.5;
    config: TypeConfig = {};

    stage: Base;

    autoPlay: boolean = false;

    strokeColor: Color = new Color(0xffffff);

    mouseRotation: number = 10;

    time: TypeTime = {
        count: 0,
    };
    frequencyArr: Array<number> = [];
    times: Array<number> = [];

    container: Object3D;
    group: Group;

    itemSize: any = { width: 1, height: 1, diagonal: 1 };

    endLogoPathLine: PathLineBase;
    pathLines: Array<PathLineBase> = [];
    pathLinesFreq: Array<number> = [];

    gsap: GSAPAnimation;
    timeLine: GSAPTimeline;

    params: any = {
        depth: 30,
        speed: -0.01,
        freq: 50,
        amp: -130,
    };

    constructor(stage) {
        this.stage = stage;
        this.group = new Group();
        this.container = new Object3D();
        this.container.add(this.group);
    }

    async load() {
        const loaders: Array<Promise<void>> = [];
        const loadList = {
            logoLine: {
                isShape: false,
                url: config.logo.source.stroke,
            },
        };
        const svgLoader = new SVGLoader();

        for (let key in loadList) {
            let item = loadList[key];

            let promise = new Promise<void>((resolve) => {
                svgLoader.load(item.url, (data) => {
                    let group = new Group();
                    const svgSize = data.xml.viewBox.animVal;
                    this.itemSize.width = svgSize.width;
                    this.itemSize.height = svgSize.height;

                    this.container.add(group);

                    this.itemSize.diagonal = Math.sqrt(
                        svgSize.width * svgSize.width + svgSize.height * svgSize.height
                    );

                    this.makeStroke(data, group);

                    resolve();
                });
            });

            loaders.push(promise);
        }

        return Promise.all(loaders);
    }

    private makeStroke(data, group) {
        let svgSize = data.xml.viewBox.animVal;
        // let g = gui.addFolder("logoLines");
        // g.close();
        data.paths.forEach((path, i) => {
            let points: Array<number> = [];
            let subPath = path.subPaths[0];
            let strokColor = path.userData.style.stroke
                .replace("rgb(", "")
                .replace(")", "")
                .split(",");
            let curvesLength = subPath.curves.length / 10;

            strokColor = strokColor.map((value) => {
                return Number(value) / 255;
            });

            // subPath.getPoints(2).forEach(({ x, y }) => {
            //     points.push(x - Number(svgSize.width * 0.5), y * -1 + svgSize.height * 0.5, 0);
            // });

            for (let i = 0, n = path.userData.node.getTotalLength(); i < n; i += 2) {
                let v2 = path.userData.node.getPointAtLength(i);
                let x = v2.x;
                let y = v2.y;
                points.push(x - Number(svgSize.width * 0.5), y * -1 + svgSize.height * 0.5, 0);
            }

            let halfWidth = window.innerWidth;
            // points.unshift(-halfWidth, points[1], 0);
            // points.push(halfWidth, points[points.length - 1 - 1], 0);

            let positions = pointToPosition(points)[0];

            if (0) {
                let positions = pointToPosition(points);
                positions.forEach((v3) => {
                    const size = 0.1;
                    const mesh = makeSimpleBox(v3, size, size, size, 0xff00ff);
                    this.container.add(mesh);
                });
            }

            let pathLine = new PathLineBase("line" + i, points, {
                color: 0xffffff,
                lineWidth: 10,
            });

            this.times.push(0);
            this.pathLinesFreq.push(0);

            if (i == data.paths.length - 1) {
                this.endLogoPathLine = pathLine;
                // this.flowEndPositions = pathLine.positions.concat([]);
            }

            pathLine.update(0);
            this.pathLines.push(pathLine);
            this.group.add(pathLine.mesh);
            // pathLine.setGui();
        });
    }

    public addBit(frequencyArr) {
        this.frequencyArr = frequencyArr;
    }

    setup() {}

    allDraw() {
        this.container.rotation.y = 0;
        gsap.to(this.container.rotation, {
            y: Math.PI * 2,
            duration: 3.5,
            ease: "Power2.easeInOut",
        });
        this.pathLines.forEach((pathLine, i) => {
            pathLine.update(2);
            pathLine.drawTo(1, 3, i * 0.2);
            // pathLine.mesh.position.z = (i - center) * this.params.depth;
        });
    }

    update() {
        let center = Math.floor(this.pathLines.length / 2);
        let frequencyLength = this.frequencyArr.length;
        this.pathLines.forEach((pathLine, i) => {
            pathLine.mesh.position.z = (i - center) * this.params.depth;

            if (this.frequencyArr.length > 0) {
                let geoPositions = pathLine.mesh.geometry.attributes.position;
                let positionLength = geoPositions.count;
                let count = 0;
                let freqPower = this.frequencyArr[i] / 255;
                if (this.frequencyArr[i] > 100) {
                    // freqPower *= 10;
                }
                this.pathLinesFreq[i] += freqPower * 2;
                this.pathLinesFreq[i] *= 0.1;
                let frequency = this.pathLinesFreq[i];

                this.times[i] += -frequency;

                let points: Array<number> = [];
                for (let j = 0, n = pathLine.pointsOrigin.length; j < n; j += 3) {
                    let progress = j / n;
                    let x = pathLine.pointsOrigin[j + 0];
                    let y = pathLine.pointsOrigin[j + 1];
                    let z = pathLine.pointsOrigin[j + 2];
                    let localY = y - pathLine.mesh.position.y;
                    let offset =
                        Math.sin(this.times[i] + progress * this.params.freq) *
                        this.params.amp *
                        frequency;
                    points.push(x, y + offset * 0.1, z);
                }
                pathLine.updateLine(points);
            }
        });
    }

    resize(stageSize = { width: 100, height: 100, aspect: 1 }) {
        let scale = 1;

        if (stageSize.width < this.itemSize.width) {
            scale = stageSize.width / this.itemSize.width;
        }
        // if (scale > 1) scale = 1;
        // scale *= stageSize.aspect -;
        this.container.scale.set(scale, scale, scale);
    }

    setGui() {
        let g = gui.addFolder("analyser").close();
        g.add(this.params, "depth", -100, 100);
        // g.add(this.params, "speed", -1, 1, 0.01);
        g.add(this.params, "freq", -100, 100, 0.01);
        g.add(this.params, "amp", -300, 300, 0.01);
        // gui.add(this, "allDraw");
    }

    drawTest() {}
}
