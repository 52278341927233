import { Color, Vector2 } from "three";

const FinalShader = {
    uniforms: {
        uTime: { type: "f", value: 0 },
        tDiffuse: { type: "t", value: null },
        uBaseColor: { type: "v3", value: new Color(0xdddddd) },
        uOpacity: { type: "f", value: 1.0 },
        uTransition: { type: "f", value: 0.0 },
        uCheck: { type: "f", value: 1.0 },
        uScale: { type: "f", value: 2.0 },
        uPower: { type: "f", value: -3.0 },
        uSize: { type: "f", value: -0.34 },
        uProgress: { type: "f", value: 0.0 },
        uResolution: { type: "v2", value: new Vector2(1.0) },
        noiseBrightness: { value: 1 },
        noiseIntensity: { value: 0.05 },
    },

    vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `,

    fragmentShader: `
    #define PI 3.1415926535897932384626433832795
    uniform sampler2D tDiffuse;
    uniform vec2 uResolution;
    uniform float uOpacity;
    uniform float uTransition;
    uniform float uPower;
    uniform float uSize;
    uniform float uScale;
    uniform float uTime;
    uniform float uProgress;
    uniform float uCheck;
    varying vec2 vUv;
    uniform vec3 uBaseColor;

    void main() {
        float aspect = uResolution.y / uResolution.x;

        vec2 center = vec2(0.5);
        float distX =  center.x - vUv.x;
        float distY =  center.y - vUv.y;

        vec2 dist = center - vec2(0.0);
        if(dist.y < 0.5)dist.y = 0.5 + 0.5 - dist.y;
        if(dist.x < 0.5)dist.x = 0.5 + 0.5 - dist.x;
        dist.y *= aspect;
        float fullRadius = sqrt(dist.x * dist.x + dist.y * dist.y);


        vec2 uv = vUv * 2. -1.0;
        uv /= fullRadius * 2.0;
        uv.y *= aspect;

        float d = length(uv);
        d = sin(pow(d,3.) - uSize * uProgress * PI) * -uPower;
        vec2 tUv = vUv;
        tUv -= vec2(0.5);
        tUv /= uScale;
        tUv *= (d * fullRadius);

        if(uResolution.x < 1024.) {
            tUv /= aspect;
        }
        tUv += vec2(0.5);

        
        float c = smoothstep(0.0,.8,d);
        vec4 tColor = texture2D(tDiffuse,tUv);
        vec4 destColor = mix(vec4(uBaseColor.rbg,1.0),tColor,c);
        if(uCheck == 1.0) {
            gl_FragColor = vec4(destColor.rgb ,1.0);
        }else{
            gl_FragColor = texture2D(tDiffuse,vUv);
        }

      }
    `,
};

export { FinalShader };
