import Plyr from "plyr";
import Main from "./assets/libs/Main";
import AudioEqualizer from "./assets/libs/webgl/AudioEqualizer";
import { gui } from "./assets/utils/GUI";

let audioList = [
    "TheHazelnuts-ItsGonnaBeAlright-AcapellaVersion.mp3",
    "AnthonyLazaro-XmasIsHere.mp3",
    "BalloonPlanet-GreatestGiftofAll.mp3",
    "foster-christmasday.mp3",
    "KyleCox-ComeOnMrs.Claus(LetMeBeYourMan).mp3",
    "LowLight-SilentNight.mp3",
    "TheDelegates-ChristmasIsComing.mp3",
];

async function setup() {
    let main = new Main(document.querySelector("#webglContainer"));
    await main.load();
    main.setup();
    main.renderStart();

    let currentAudioId = 0;

    audioList = audioList.sort((a, b) => {
        return 0.5 - Math.random();
    });

    let isPlayed = false;
    let isLoad = false;
    let audioEqualizer;
    let buttonEl = document.querySelector("button");
    let audioElement = document.querySelector("audio");

    gui.add({ audio: audioList[0] }, "audio", audioList).onChange((value) => {
        audioLoad(value);
    });

    audioElement.addEventListener("ended", () => {
        currentAudioId++;
        currentAudioId %= audioList.length;
        audioLoad(audioList[currentAudioId]);
    });

    buttonEl.addEventListener("click", () => {
        if (isPlayed) {
            buttonEl.innerText = "PLAY";
            audioElement.pause();
            isPlayed = false;
        } else {
            isPlayed = true;
            buttonEl.innerText = "PAUSE";
            if (!isLoad) {
                audioLoad();
            }
            if (audioElement.paused) audioElement.play();
            isLoad = true;
        }
        buttonEl.remove();
    });

    const audioLoad = (src = audioList[currentAudioId]) => {
        audioElement.onloadedmetadata = () => {
            audioElement.play();
        };
        audioElement.src = "./assets/" + src;

        if (!audioEqualizer) {
            audioEqualizer = new AudioEqualizer(audioElement);
            audioEqualizer.start();
            audioEqualizer.onUpdate = (frequencyArr) => {
                main.logo.addBit(frequencyArr);
                main.effectParams.bloomStrength = 0.3 + (frequencyArr[0] / 255) * 0.4;
            };

            audioEqualizer.changeVolume(0.5);

            audioElement.addEventListener("ended", () => {
                currentAudioId++;
                currentAudioId %= audioList.length;
                audioLoad(audioList[currentAudioId]);
            });
        }
        // audioElement.play();
    };

    document.addEventListener("visibilitychange", () => {
        if (!isPlayed) return;
        if (document.visibilityState === "visible") {
            audioElement.play();
        } else {
            audioElement.pause();
        }
    });
}

window.addEventListener("load", setup);
