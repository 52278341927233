/**
 * @author felixturner / http://airtight.cc/
 *
 * RGB Shift Shader
 * Shifts red and blue channels from center in opposite directions
 * Ported from http://kriss.cx/tom/2009/05/rgb-shift/
 * by Tom Butterworth / http://kriss.cx/tom/
 *
 * amount: shift distance (1 is width of input)
 * angle: shift angle in radians
 */

import { Vector2 } from "three";

const ZoomBlur = {
    uniforms: {
        tDiffuse: { type: "t", value: null },
        strength: { type: "f", value: 0.0 },
        opacity: { type: "f", value: 1.0 },
        center: { type: "v2", value: new Vector2(0, 0) },
        resolution: { type: "v2", value: new Vector2(0, 0) },
    },

    vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `,

    fragmentShader: `

    uniform sampler2D tDiffuse;
    uniform float strength;
    uniform float opacity;
    uniform vec2 center;
    uniform vec2 resolution;

    varying vec2 vUv;

    float random(vec3 scale, float seed){
      return fract(sin(dot(gl_FragCoord.stp + seed, scale)) * 43758.5453 + seed);
    }


    void main() {

      if(strength == 0.0){
        gl_FragColor=texture2D(tDiffuse,vUv);
      }else{

        vec4 color=vec4(0.0);
        vec2 toCenter=center-vUv*resolution;

        float offset=random(vec3(12.9898,78.233,151.7182),0.0);
        float total   = 0.0;
        float percent = 0.0;
        float weight  = 0.0;

        for(float t=0.0;t<=30.0;t++){
          percent=(t+offset)/30.0;
          weight=(percent-percent*percent);
          vec4 tColor = texture2D(tDiffuse,vUv+toCenter*percent*strength/resolution);
          tColor.rgb*=tColor.a;
          color+=tColor*weight;
          total+=weight;
        }
        gl_FragColor=color/total;
        gl_FragColor.rgb/=gl_FragColor.a;
        gl_FragColor.a = opacity;
      }
      

    }
    `,
};

export { ZoomBlur };
