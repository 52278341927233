import { BufferAttribute, BufferGeometry, Color, Group, Points, ShaderMaterial } from "three";

export default class Particle {
    group: Group = new Group();
    nums: number = 1500;
    scale: number = 150;
    particles: Points;
    constructor() {
        const positions = new Float32Array(this.nums * 3);
        const velocities = new Float32Array(this.nums * 3);
        const scales = new Float32Array(this.nums);
        const times = new Float32Array(this.nums);
        const speeds = new Float32Array(this.nums);

        let count = 0;
        let spread = 1500;
        for (let i = 0; i < this.nums; i += 3) {
            positions[i] = Math.random() * spread - spread * 0.5;
            positions[i + 1] = Math.random() * spread - spread * 0.5; // y
            positions[i + 2] = Math.random() * spread - spread * 0.5; // z

            velocities[i] = Math.random() * 300 - 150;
            velocities[i + 1] = Math.random() * 300;
            velocities[i + 2] = Math.random() * 300;

            scales[count] = this.scale;
            times[count] = 0;
            speeds[count] = Math.random() * 0.01;
            count++;
        }

        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(positions, 3));
        geometry.setAttribute("velocity", new BufferAttribute(velocities, 3));
        geometry.setAttribute("scale", new BufferAttribute(scales, 1));
        geometry.setAttribute("time", new BufferAttribute(times, 1));
        geometry.setAttribute("speed", new BufferAttribute(speeds, 1));

        const material = new ShaderMaterial({
            uniforms: {
                color: { value: new Color(0xffffff) },
            },
            vertexShader: `
                attribute float scale;
                attribute float time;
                attribute float speed;
                attribute vec3 velocity;

                varying float vOpacity;

                void main() {

                    vec3 transformed = position;
                    transformed.x -= cos(time * 2.) * velocity.x;
                    transformed.y -= time * velocity.y;
                    transformed.z -= time * velocity.z;

                    vOpacity = 1.0 - abs(time - 0.5) / 0.5;

                    vec4 modelPosition = modelMatrix * vec4(transformed, 1.0);
                    vec4 viewPosition = viewMatrix * modelPosition;
            
                    gl_PointSize = scale * (1.0 - time);
                    gl_PointSize *= (100.0 / - viewPosition.z); //sizeAttenuation: true

                    gl_Position = projectionMatrix * modelViewMatrix * vec4( transformed, 1.0 );

                }
            `,
            fragmentShader: `
                uniform vec3 color;
                varying float vOpacity;

                void main() {

                    if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
                    gl_FragColor = vec4( color, vOpacity );

                }
            `,
            transparent: true,
            depthTest: true,
        });

        this.particles = new Points(geometry, material);
        this.group.add(this.particles);
        this.update();
    }

    update() {
        let geometry = this.particles.geometry;
        let attributes = geometry.attributes;
        let attrTime = attributes.time;
        let attrSpeed = attributes.speed;
        let velocity = attributes.velocity;

        for (let i = 0; i < attrTime.array.length; i++) {
            //@ts-ignore
            attrTime.array[i] += attrSpeed.array[i];

            if (attrTime.array[i] > 1) {
                //@ts-ignore
                attrTime.array[i] = 0;
            }
        }

        attrTime.needsUpdate = true;
        attrSpeed.needsUpdate = true;
        velocity.needsUpdate = true;
    }
}
