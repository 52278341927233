import gsap from "gsap";
import {
    BackSide,
    DoubleSide,
    FrontSide,
    Mesh,
    MeshBasicMaterial,
    PlaneGeometry,
    TextureLoader,
    Vector3,
} from "three";
import { gui, gui_simple, gui_vec3 } from "../utils/GUI";
import Base from "./webgl/Base";
import Logo from "./webgl/Logo";
import Particle from "./webgl/Particle";
import { OrbitControls } from "./webgl/util/OrbitControls";

export default class Main extends Base {
    orbitCtrl: OrbitControls;
    logo: Logo;
    particle: Particle;
    title: Mesh;
    sevenseed: Mesh;
    santa: Mesh;
    santaLimitX: number = 600;
    santaSpeed: number = Math.random() * 2;
    moon: Mesh;
    constructor(containerEl, operationMode: boolean = false) {
        super(containerEl, { render_clearColor: 0x3e5986 });

        this.logo = new Logo(this);
        this.particle = new Particle();
        this.logo.container.add(this.particle.group);
        // this.logo.container.position.y = -200;

        let titleTexture = new TextureLoader().load("./assets/mery.png", (t) => {
            let width = t.image.width;
            let height = t.image.height;
            this.title = new Mesh(
                new PlaneGeometry(width, height),
                new MeshBasicMaterial({
                    map: t,
                    side: FrontSide,
                    transparent: true,
                    color: 0xffffff,
                })
            );
            let scale = 0.5;
            this.title.position.y = height;
            this.title.scale.set(scale, scale, scale);
            this.logo.container.add(this.title);
        });

        let logoTexture = new TextureLoader().load("./assets/logo.png", (t) => {
            let width = t.image.width;
            let height = t.image.height;
            this.sevenseed = new Mesh(
                new PlaneGeometry(width, height),
                new MeshBasicMaterial({
                    map: t,
                    side: BackSide,
                    transparent: true,
                    color: 0xffffff,
                })
            );
            let scale = 0.5;
            // this.sevenseed.rotation.y = Math.PI;
            this.sevenseed.position.y = height;
            this.sevenseed.scale.set(-scale, scale, scale);
            this.logo.container.add(this.sevenseed);
        });

        let santaTexture = new TextureLoader().load("./assets/santa.png", (t) => {
            let width = t.image.width;
            let height = t.image.height;
            this.santa = new Mesh(
                new PlaneGeometry(width, height),
                new MeshBasicMaterial({
                    map: t,
                    side: DoubleSide,
                    opacity: 0,
                    transparent: true,
                })
            );
            let scale = 0.5;
            this.santa.position.x = -this.santaLimitX;
            this.santa.position.y = Math.random() * 200 + 200;
            this.santa.position.z = -220;
            this.santa.scale.set(scale, scale, scale);
            this.logo.container.add(this.santa);
        });

        this.moon = new Mesh(
            new PlaneGeometry(50, 50),
            new MeshBasicMaterial({
                map: new TextureLoader().load("./assets/moon.png"),
                transparent: true,
                side: DoubleSide,
            })
        );

        this.moon.position.x = -300;
        this.moon.position.y = 300;
        this.moon.rotation.z = (-30 * Math.PI) / 180;
        this.logo.container.add(this.moon);

        this.addResizeList("sevenSeed", () => {
            this.logo.resize(this.stage);
        });

        // this.scene.add(this.particle.group);
    }

    async load() {
        return this.logo.load();
    }

    async setup() {
        this.orbitCtrl = new OrbitControls(this.camera, this.renderer.domElement);

        this.orbitCtrl.enabled = true;
        this.orbitCtrl.enableZoom = false;
        this.orbitCtrl.enableDamping = true;
        this.orbitCtrl.target.set(0, 0.35, 0);
        this.orbitCtrl.update();

        this.scene.add(this.logo.container);
        this.logo.setGui();
        this.logo.allDraw();

        let data = { progress: 0 };
        gsap.to(data, {
            progress: 1,
            duration: 2,
            ease: "Power4.easeInOut",
            onUpdate: () => {
                this.filnalShaderPass.uniforms.uProgress.value = data.progress;
            },
        });

        this.setGui();

        this.logo.resize(this.stage);
    }

    update() {
        if (this.orbitCtrl) this.orbitCtrl.update();

        if (this.zoomBlurPass) {
            this.zoomBlurPass.uniforms["strength"].value = this.effectParams.zoomBlurStrength;
        }

        if (this.logo) {
            this.logo.update();
        }

        if (this.particle) {
            this.particle.update();
        }

        if (this.santa) {
            this.santa.position.x += 1;
            if (this.santa.position.x > this.santaLimitX) {
                //@ts-ignore
                this.santa.material.opacity *= 0.9;
                if (this.santa.material.opacity < 0.01) {
                    this.santa.position.x = -this.santaLimitX;
                    this.santa.position.y = Math.random() * 200 + 200;
                }
            } else {
                if (this.santa.material.opacity < 1) {
                    this.santa.material.opacity += 0.1;
                }
            }
        }
    }

    setGui() {
        gui_vec3(gui, this.camera.position, {
            folder: "camera",
            range: 10000,
            threshold: 0.1,
        }).close();

        // gui_vec3(gui, this.santa.position, {});
        if (!this.bloomPass) {
            let g = gui.addFolder("effect");
            g.add(this.effectParams, "bloomThreshold", 0.0, 1.0).onChange((value) => {
                this.bloomPass.threshold = Number(value);
            });

            g.add(this.effectParams, "bloomStrength", 0.0, 3.0)
                .listen()
                .onChange((value) => {
                    this.bloomPass.strength = Number(value);
                });

            g.add(this.effectParams, "bloomRadius", 0.0, 1.0)
                .step(0.01)
                .onChange((value) => {
                    this.bloomPass.radius = Number(value);
                });
        }

        if (this.zoomBlurPass) {
            // g.add(this.effectParams, "zoomBlurStrength", 0, 1, 0.01).onChange((value) => {
            //     this.zoomBlurPass.uniforms["strength"].value = value;
            // });
            // g.add(this.effectParams, "zoomBlurOpacity", 0, 1).onChange((value) => {
            //     this.zoomBlurPass.uniforms["opacity"].value = value;
            // });
        }

        let g = gui.addFolder("ball").close();
        g.add({ ball: true }, "ball").onChange((value) => {
            this.filnalShaderPass.uniforms.uCheck.value = value ? 1 : 0;
        });
        // g.add(this.effectParams, "uOpacity", 0, 1, 0.01).onChange((value) => {
        //     this.filnalShaderPass.uniforms.uOpacity.value = value;
        // });

        g.add(this.effectParams, "uScale", 0, 40, 0.1).onChange((value) => {
            this.filnalShaderPass.uniforms.uScale.value = value;
        });

        g.add(this.effectParams, "uPower", 0, 50, 0.1).onChange((value) => {
            this.filnalShaderPass.uniforms.uPower.value = value;
        });
        g.add(this.effectParams, "uSize", 0, 1, 0.01).onChange((value) => {
            this.filnalShaderPass.uniforms.uSize.value = value;
        });
    }
}
