import gsap from "gsap";
import { MeshLineGeometry, MeshLineMaterial } from "meshline";
import { Mesh, Vector2, Vector3 } from "three";
import { gui } from "../../../utils/GUI";
import { makeCurvePoint, pointToPosition, positionToPoint } from "../utils";

export default class PathLine {
    meshLineGeometry: MeshLineGeometry;
    mesh;
    name = "";
    id;

    gsap;
    config = {
        transparent: true,
        lineWidth: 1,
        color: 0xffffff,
        dashArray: 2, // always has to be the double of the line
        dashOffset: 1, // start the dash at zero
        dashRatio: 0.5,
        opacity: 1,

        curve: 0,
    };

    direction = 1;
    progress = 0;
    curveDivision = 5000;
    isCurve = false;

    curve;
    curves: Array<Vector3> = [];
    points: Array<number> = []; // number x,y,z...
    positions: Array<Vector3> = [];
    pointsOrigin;

    tangents;
    normals;
    binormals;

    constructor(name = "", points = [0, 0, 0], option = {}) {
        this.id = 0;
        this.name = name;
        this.points = points;
        this.config = Object.assign(this.config, option);

        const lineMaterial = new MeshLineMaterial({
            lineWidth: this.config.lineWidth,
            color: this.config.color,
            dashArray: this.config.dashArray,
            dashOffset: this.config.dashOffset,
            dashRatio: this.config.dashRatio,
            opacity: this.config.opacity,
            resolution: new Vector2(1, 1),
        });

        lineMaterial.transparent = true;

        this.mesh = new Mesh(this.updateGeometry(), lineMaterial);
    }

    updateLine(points) {
        if (points) this.points = points;
        if (this.mesh.geometry) this.mesh.geometry.dispose();
        this.mesh.geometry = this.updateGeometry();
    }

    updateGeometry() {
        let points: Array<number> = [];
        if (this.config.curve > 0) {
            const data = makeCurvePoint(this.config.curve, pointToPosition(this.points));
            this.curve = data.curve;
            points = data.points;
            this.curves = pointToPosition(points);
        } else {
            points = this.points;
        }

        this.positions = pointToPosition(this.points);
        if (!this.pointsOrigin) this.pointsOrigin = points.concat([]);

        // let meshline
        if (!this.meshLineGeometry) {
            this.meshLineGeometry = new MeshLineGeometry();
        }

        this.meshLineGeometry.setPoints(points);
        return this.meshLineGeometry;
    }

    computeFrenetFrames() {
        let data = this.curve.computeFrenetFrames(this.points.length / 3);
        this.binormals = data.binormals;
        this.tangents = data.tangents;
        this.normals = data.normals;
    }

    getCurves() {
        return this.curves;
    }

    getCurvesToPoints() {
        return positionToPoint(this.curves);
    }

    getPositions() {
        return pointToPosition(this.points);
    }

    setGui(guiFolder = null) {
        let _gui = guiFolder ? guiFolder : gui;
        let g = _gui.addFolder(this.name).close();
        g.add(this, "progress", 0, 1, 0.001).onChange(() => {
            this.update(this.progress);
        });
        g.add(this.mesh.material, "lineWidth", 0, 5).listen();
        g.add(this.mesh.material, "dashArray", 0, 2).listen();
        g.add(this.mesh.material, "dashOffset", 0, 2, 0.001).listen();
        g.add(this.mesh.material, "dashRatio", 0, 1).listen();
        g.add(this.mesh, "visible");
        g.add(this.config, "curve", 0, 5000).onChange(() => {
            this.updateGeometry();
        });

        g.add(
            {
                ポイント位置Copy: () => {
                    const handler = (e) => {
                        document.removeEventListener("copy", handler);
                        e.clipboardData.setData("text/plain", JSON.stringify(this.points));
                        e.preventDefault();
                    };
                    document.addEventListener("copy", handler);
                    document.execCommand("copy");
                },
            },
            "ポイント位置Copy"
        );
    }

    update(progress = this.progress) {
        if (this.direction == 1) {
            this.progress = progress;
        } else {
            this.progress = progress;
        }
        this.drawLine();
    }

    dispose() {
        this.mesh.geometry.dispose();
        this.mesh.material.dispose();
    }

    // updateProperty(prop){
    //     if(this.mesh.material[prop]) {
    //     }
    // }

    drawLine() {
        this.mesh.material.dashOffset = this.progress;
    }

    drawTo(to, duration = 2, delay = 0, complete = () => {}) {
        if (this.gsap) this.gsap.kill();
        this.gsap = gsap.to(this, {
            progress: to,
            delay: delay,
            duration: duration,
            ease: "Power4.easeInOut",
            onUpdate: () => {
                this.drawLine();
            },
            onComplete: () => {
                complete();
            },
        });
    }
}
