export default class AudioEqualizer {
    isInit = false;
    isRender = false;
    isPlayed = false;
    audio = null;
    panner: StereoPannerNode;
    gainNode: GainNode;
    context: AudioContext;
    analyser: AnalyserNode;
    source: MediaElementAudioSourceNode;
    frequency: Uint8Array;
    iirfilter: IIRFilterNode;
    currentVolume: number = 0;

    lowPassCoefs = [
        {
            frequency: 1000,
            feedforward: [0.00020298, 0.0004059599, 0.00020298],
            feedback: [1.0126964558, -1.9991880801, 0.9873035442],
        },
        {
            frequency: 500,
            feedforward: [0.0012681742, 0.0025363483, 0.0012681742],
            feedback: [1.0317185917, -1.9949273033, 0.9682814083],
        },
        {
            frequency: 1000,
            feedforward: [0.0050662636, 0.0101325272, 0.0050662636],
            feedback: [1.0632762845, -1.9797349456, 0.9367237155],
        },
        {
            frequency: 5000,
            feedforward: [0.1215955842, 0.2431911684, 0.1215955842],
            feedback: [1.2912769759, -1.5136176632, 0.7087230241],
        },
    ];

    constructor(audioElement) {
        this.context = new AudioContext();
        this.source = this.context.createMediaElementSource(audioElement);

        this.analyser = this.context.createAnalyser();
        this.gainNode = this.context.createGain();
        this.panner = new StereoPannerNode(this.context, { pan: 0 });

        this.iirfilter = new IIRFilterNode(this.context, {
            feedforward: this.lowPassCoefs[0].feedforward,
            feedback: this.lowPassCoefs[0].feedback,
        });

        this.source
            .connect(this.gainNode)
            .connect(this.panner)
            .connect(this.analyser)
            .connect(this.context.destination);

        //@ts-ignore
        window.gainNode = this.gainNode;
        //@ts-ignore
        window.panner = this.panner;
        //@ts-ignore
        window.aq = this;

        this.frequency = new Uint8Array(this.analyser.frequencyBinCount);

        // now instead of connecting to aCtx.destination, connect to the gainNode
        // this.source.connect(this.gainNode);
    }

    onIirFilter() {
        // this.source.disconnect();
        this.source.disconnect(this.gainNode);
        // this.source.connect(this.iirfilter).connect(this.context.destination);

        this.source
            .connect(this.iirfilter)
            .connect(this.gainNode)
            .connect(this.panner)
            .connect(this.analyser)
            .connect(this.context.destination);
    }
    offIirFilter() {
        this.source.disconnect(this.iirfilter);

        this.source
            .connect(this.gainNode)
            .connect(this.panner)
            .connect(this.analyser)
            .connect(this.context.destination);

        // this.source.disconnect(this.iirfilter);
        // this.source.disconnect(this.analyser);
        // this.source.disconnect(this.panner);
        // this.source.connect(this.context.destination);
        // this.changeVolume();
        // this.source
        // 	.connect(this.gainNode)
        // 	.connect(this.panner)
        // 	.connect(this.analyser)
        // 	.connect(this.context.destination);
    }
    destory() {
        this.isRender = false;
        // this.source.disconnect(this.analyser);
        // this.source.connect(this.context.destination);
    }

    changeVolume(value = this.currentVolume) {
        console.log(`🚀 ~ changeVolume  value `, value);
        this.gainNode.gain.value = value; // 10 %
        this.currentVolume = value;
        // this.gainNode.gain.setValueAtTime(value, this.context.currentTime);
    }

    start() {
        if (this.isRender) return;
        this.isRender = true;
        this.isPlayed = true;
        requestAnimationFrame(this.update.bind(this));
    }

    onUpdate = (arr) => {};
    update() {
        this.analyser.getByteFrequencyData(this.frequency);
        this.onUpdate(this.frequency);
        if (this.isRender) requestAnimationFrame(this.update.bind(this));
    }
}
