import { BoxGeometry, Mesh, MeshBasicMaterial, Vector3, CatmullRomCurve3 } from "three";

function randomColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

function makeCurvePoint(num = 500, positions = []) {
    let curve = new CatmullRomCurve3(positions);
    let points = [];

    curve.getPoints(num).forEach(({ x, y, z }) => {
        points.push(x, y, z);
    });

    return { curve, points };
}

function pointToPosition(points) {
    const positions = [];
    for (let i = 0; i < points.length; i += 3) {
        positions.push(new Vector3(points[i + 0], points[i + 1], points[i + 2]));
    }

    return positions;
}

function positionToPoint(positions) {
    let points = [];

    positions.forEach(({ x, y, z }) => {
        points.push(x, y, z);
    });

    return points;
}

function makeSimpleBox(vec3, w = 10, h = 10, d = 10, color = 0xff0000) {
    let geo = new BoxGeometry(w, h, d);
    let mat = new MeshBasicMaterial({ color: color });
    let mesh = new Mesh(geo, mat);
    mesh.position.copy(vec3);
    return mesh;
}

function changeDecimal(value, decimal = 1000) {
    return Math.round(value * decimal) / decimal;
}

function clipBoardCopy(data) {
    const handler = (e) => {
        document.removeEventListener("copy", handler);
        e.clipboardData.setData("text/plain", JSON.stringify(data));
        e.preventDefault();
    };
    document.addEventListener("copy", handler);
    document.execCommand("copy", true, "11");
}

export {
    makeCurvePoint,
    pointToPosition,
    positionToPoint,
    makeSimpleBox,
    changeDecimal,
    clipBoardCopy,
    randomColor,
};
